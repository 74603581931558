import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { HemoglobinaEntity } from '../entities/hemoglobina.entity';														   
import { EstadisticaEntity } from '../entities/estadistica.entity';


@Injectable()
export class PerinatalService {

    private BASE_URL: string = 'http://www.cnccohortstudy.com/cohort_backend/public/api/perinatal/';
    constructor(
        private httpClient: HttpClient
    ) { }


    findAll(page: number, id_usuario: number){
        return this.httpClient.get(this.BASE_URL + 'findAll/'+ page +'/'+ id_usuario)
             .toPromise()
             .then(res => res as EstadisticaEntity[]);
     }
 
     find( colum: string, value: string){
         return this.httpClient.get(this.BASE_URL + 'find/'+ colum + '/'+value)
         .toPromise()
         .then(res => res as EstadisticaEntity);
     }
     
    create(estadistica: EstadisticaEntity, hemos: Array<HemoglobinaEntity>) {

        let array: Array<any> = [estadistica, hemos];
        console.log(JSON.stringify(array));
        var body = JSON.stringify(array);

        return this.httpClient.post(this.BASE_URL + 'create', body, { observe: 'body', responseType: 'json' })
    }

    delete(id: number) {

        return this.httpClient.get(this.BASE_URL + 'delete/' + id, { responseType: 'json' });
    }

    update(estadistica: EstadisticaEntity , hemos: Array<HemoglobinaEntity>) {
        
        let array: Array<any> = [estadistica,hemos];
        console.log(JSON.stringify(array));
        var body = JSON.stringify(array);

        return this.httpClient.post(this.BASE_URL + 'update', body, { observe: 'body', responseType: 'json' })
    }
    
    // comentario prueba 


}