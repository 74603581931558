import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, 
UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../../../service/login.service';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public loginService: LoginService,
    public router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.loginService.isLoggedIn == false) {
        this.router.navigate(['login'])

      

      /*   Swal.fire({
            icon: 'error',
            title: 'Acceso Denegado',

            didClose: () => {
              
            }

          }) */
   
    }
    return true;
  }
}