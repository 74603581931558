import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Menú'
  },
  
    
  {
    name: 'Tablero',  /* nav-icon icon-rocket icon-speedometer icon-wrench */
    icon: 'icon-pie-chart',
    url: '/dashboard',
  },
  {
    name: 'Insertar Datos',
    icon: 'icon-note',
    children: [
      {
        name: 'Estadística Perinatal',
        url: '/insert/estadisticaPerinatalForm/0',
        icon: 'icon-layers'
      },
      {
        name: 'Estadística 7 meses',
        url: '/insertSieteMeses/insertSieteMeses/0',
        icon: 'icon-layers'
      }
    
    ]
  },
  {
    name: 'Mostrar Datos',
    icon: 'icon-list',
    children: [
      {
        name: 'Estadística Perinatal',
        url: '/list/estadisticaPerinatalList',
        icon: 'icon-layers'
      },

      {
        name: 'Estadística 7 meses',
        url: '/listSieteMeses/estadisticaSieteMeseslList',
        icon: 'icon-layers'
      },

  
    ]
  }
];
