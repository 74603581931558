import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class DashboardService{

    private BASE_URL: string = 'http://www.cnccohortstudy.com/cohort_backend/public/api/dashboard/';
    constructor(
        private httpClient: HttpClient
    ){}

    infoPerinatal(){
       return this.httpClient.get(this.BASE_URL +'infoperinatal')
    }
    

}