import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NomencladorEntity} from '../entities/nomenclador.entity';

@Injectable()
export class NomencladorService{

    private BASE_URL: string = 'http://www.cnccohortstudy.com/cohort_backend/public/api/nomenclador/';
    constructor(
        private httpClient: HttpClient
    ){}

    findAll( table : string, bd: string){
       return this.httpClient.get(this.BASE_URL + 'findAll/'+ table+ '/' + bd)
            .toPromise()
            .then(res => res as NomencladorEntity[]);
    }

}


