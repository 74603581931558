import { Component, OnInit } from '@angular/core';
import { navItems } from '../../_nav';

import { LoginService } from '../../service/login.service';
import { CookieService } from 'ngx-cookie-service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;
  public name ;

  constructor(
    private loginService: LoginService,
    private cookieService: CookieService
  ) {

  }  

  ngOnInit(): void {
     this.name = atob(this.cookieService.get(btoa('user_name')))
  }

  logout() {

    Swal.fire({
      title: '¿Está seguro que desea desautenticarse?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar'

    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Desautenticando',
          showConfirmButton: false,
          timer: 1200
        })
        this.loginService.logout()
      }
    })


  }


  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }




}
