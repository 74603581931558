import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

import { LoginComponent }  from './views/loginUser/login/login.component';
import { AuthGuard } from "./views/loginUser/login/auth.guard";
 


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard], 
    data: {
      title: ''
    },
    children: [
      {
        path: 'insert',
        loadChildren: () => import('./views/insert/insert.module').then(m => m.InsertModule)
      },
      {
        path: 'list',
        loadChildren: () => import('./views/list/list.module').then(m => m.ListModule)
      },
      {
        path: 'listSieteMeses',
        loadChildren: () => import('./views/list-siete-meses/list.module').then(m => m.ListModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'insertSieteMeses',
        loadChildren: () => import('./views/insert-siete-meses/insertSieteMeses.module').then(m => m.insertSieteMesesModule)
      }
  
    ]
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  }, 
  { path: '**', component: P404Component }

];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
