import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
												   
import { EstadisticaSieteMesesEntity } from '../entities/estadisticaSieteMeses.entity';


@Injectable()
export class SieteMesesService {

    private BASE_URL: string = 'http://www.cnccohortstudy.com/cohort_backend/public/api/estadisticaSieteMeses/';
    constructor(
        private httpClient: HttpClient
    ) { }


    findAll(page: number, id_usuario: number){
        return this.httpClient.get(this.BASE_URL + 'findAll/'+ page +'/'+ id_usuario)
             .toPromise()
             .then(res => res as EstadisticaSieteMesesEntity[]);
     }
 
     find( colum: string, value: string){
         return this.httpClient.get(this.BASE_URL + 'find/'+ colum + '/'+value)
         .toPromise()
         .then(res => res as EstadisticaSieteMesesEntity);
     }
     
    create(estadistica: EstadisticaSieteMesesEntity) {

        let array: Array<any> = [estadistica];
        console.log(JSON.stringify(array));
        var body = JSON.stringify(array);

        return this.httpClient.post(this.BASE_URL + 'create', body, { observe: 'body', responseType: 'json' })
    }

    delete(id: number) {

        return this.httpClient.get(this.BASE_URL + 'delete/' + id, { responseType: 'json' });
    }

    update(estadistica: EstadisticaSieteMesesEntity) {
        
        let array: Array<any> = [estadistica];
        console.log(JSON.stringify(array));
        var body = JSON.stringify(array);

        return this.httpClient.post(this.BASE_URL + 'update', body, { observe: 'body', responseType: 'json' })
    }
    
    // comentario prueba 


}