import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { UserEntity } from '../entities/user.entiy';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private BASE_URL: string = 'http://www.cnccohortstudy.com/cohort_backend/public/api/login/';


  constructor(
    private httpClient: HttpClient,
    private router: Router,
     private cookieService: CookieService
  ) { }


  createUser(user: UserEntity) {

    var body = JSON.stringify(user);
    return this.httpClient.post(this.BASE_URL + 'create', body, { observe: 'body', responseType: 'json' })
  }

  findAll(){
    return this.httpClient.get(this.BASE_URL + 'findAll')
         .toPromise()
         .then(res => res as UserEntity[]);
 }

  userDerails(token: string) {

  const headers= new HttpHeaders()
  .set('Access-Control-Allow-Headers', '*')
  .set('Access-Control-Allow-Origin', '*')
  .set('Access-Control-Allow-Methods', '*')
  .set('Authorization', token);
  
    return this.httpClient.get(this.BASE_URL + 'userdata', {'headers': headers}) 
  }

  login(user: UserEntity) {
    var body = JSON.stringify(user);
    console.log(body);
    return this.httpClient.post(this.BASE_URL + 'login', body, { observe: 'body', responseType: 'json' })
  }

  logout(): void {
    
    this.cookieService.delete(btoa('token'));
    this.cookieService.delete(btoa('user_name'));
    this.cookieService.delete(btoa('user_id'));

    if (!this.cookieService.check(btoa('token'))) {
      this.router.navigate(['/login']);
    }
  
  
  }

  get isLoggedIn(): boolean {

    if (this.cookieService.check(btoa('token'))) {

      return true 
/*       this.userDerails(atob(this.cookieService.get(btoa('token'))))
        .subscribe({
          next: data => {

            if (!data['error']) {
  
              return true

            } else {
  
              return false
            }

          },
          error: error => {
            console.error("Server Error", error)

            return false
          }
        }
        ); */

    } else {

      return false

    }


  }

}