
//Angular Default 
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Transition and animations
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

//Sevices

import {NomencladorService} from './service/nomenclador.service';
import {PerinatalService} from './service/perinatal.service';
import {HemoService} from './service/hemoglobina.service';
import {DashboardService} from './service/dashboard.service';
import { LoginService } from './service/login.service';
import {SieteMesesService} from './service/sieteMeses.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
//Componet
import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';



import { ReactiveFormsModule , FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

/* const APP_CONTAINERS = [
  DefaultLayoutComponent
]; */

import {
  /* AppAsideModule, */
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
 
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './views/loginUser/login/login.component';




@NgModule({
  
  imports: [
    SweetAlert2Module.forRoot(),
    SweetAlert2Module,
    SweetAlert2Module.forChild({ /* options */ }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
  /*   AppAsideModule, */
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    FormsModule,
    HttpClientModule,
    AppSidebarModule,
    ReactiveFormsModule,
    FormsModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgbModule,
   
  ],
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    P404Component,
    P500Component,
    LoginComponent,
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  },  
  PerinatalService,
  NomencladorService,
  LoginService,
  HemoService,
  DashboardService,
  SieteMesesService
 
],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
