import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HemoglobinaEntity} from '../entities/hemoglobina.entity';

@Injectable()
export class HemoService{

    private BASE_URL: string = 'http://www.cnccohortstudy.com/cohort_backend/public/api/hemo/';
    constructor(
        private httpClient: HttpClient
    ){}

    findAll(){
       return this.httpClient.get(this.BASE_URL + 'findAll')
            .toPromise()
            .then(res => res as HemoglobinaEntity[]);
    }
    
    find( colum: string, value: number){
        return this.httpClient.get(this.BASE_URL + 'find/'+ colum + '/'+value)
        .toPromise()
        .then(res => res as HemoglobinaEntity[]);
    }
    
}


