import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../service/login.service';
import { UserEntity } from '../../../entities/user.entiy';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import {
  Router,
  ActivatedRoute,
} from '@angular/router';
import { now } from 'moment';


/* declare global {
  interface Window {
   PasswordCredential: any;
   FederatedCredential: any;
  }
 } */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  user: UserEntity;

  constructor(
    private loginService: LoginService,
    private cookieService: CookieService,
    private router: Router,
    private activatedRute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.user = new UserEntity;
  }

  login() {

    if (this.user.name != null && this.user.password != null) {

      this.loginService.login(this.user)
        .subscribe({
          next: data => {

            if (data['error']) { // hubo error al insertar 

              Swal.fire({
                icon: 'error',
                title: data['messages'],

              })

            } else {  // no hubo error al insertar
              Swal.fire({
                icon: 'success',
                title: data['messages'],

                didClose: () => {
                  let fecha = new Date()
                  /*     var minute = fecha.getMinutes(); */
                  /*     fecha.setMinutes(minute + 1);  */
                  var hora = fecha.getHours();
                  fecha.setHours(hora + 1);
                  // console.log(fecha);
                  this.cookieService.set(btoa('token'), btoa(data['token']), { expires: fecha });
                  this.cookieService.set(btoa('user_name'), btoa(data['user_name']), { expires: fecha });
                  this.cookieService.set(btoa('user_id'), btoa(data['user_id']), { expires: fecha });
                  this.cookieService.set(btoa('user_rol'), btoa(data['user_rol']), { expires: fecha });


                  this.router.navigate(['/dashboard']);

                }

              })
            }
          },
          error: error => {
            console.error("Server Error", error)
            Swal.fire({
              icon: 'error',
              title: "Ha ocurrido un error contacte con los administradores",

            })

          }
        }
        );
    } else {

      Swal.fire({
        icon: 'error',
        title: "Nombre o Contraseña vacío",

      })

    }

  }





}
